<template>
  <section>
    <HomeBanner class="desktop-only"/>
    <HomeBannerMobile class="desktop-hide"/>
    <HomeSearch class="desktop-only"/>
    <HomeSearchMobile class="desktop-hide"/>
    
    <q-card class="container" flat>
      <q-tabs
        v-model="tab"
        class="bg-white text-black"
        align="justify"
        narrow-indicator
        active-color="primary"
        indicator-color="accent"
      >
        <q-tab icon="feed" name="news" label="News" />
        <q-tab icon="grade" name="features" label="Features" />
        <q-tab icon="comment" name="testimonials" label="Testimonials" />
      </q-tabs>
      <q-separator />
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="news">
          <HomeNews/>
        </q-tab-panel>
        <q-tab-panel name="features">
          <HomeFeatures/>
        </q-tab-panel>
        <q-tab-panel name="testimonials">
          <HomeTestimonials/>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </section>
</template>
<script setup>
import HomeBanner from "../components/Home/HomeBanner.vue";
import HomeBannerMobile from "../components/Home/HomeBannerMobile.vue";
import HomeNews from "@/components/Home/HomeNews.vue";
import HomeFeatures from "@/components/Home/HomeFeatures.vue";
import HomeTestimonials from "@/components/Home/HomeTestimonials.vue";
import HomeSearch from "@/components/Home/HomeSearch.vue";
import HomeSearchMobile from "@/components/Home/HomeSearchMobile.vue";
import { ref } from 'vue';
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const store = useStore();
const route = useRoute();
const router = useRouter();
const tab = ref('news');
// If there is a passed qid
if (route.query.qid !== undefined) {
  store.dispatch("qr/transmit", route.query.qid);
  router.replace('/');
}

function getYards() {
  store.dispatch("vehicle/fetchYards").then((r) => {
    if (r.yards) { yardArry(r.yards);}
  });
}

async function yardArry(yards) {
  await yards.map((yard) => {
    yard["label"] = yard.name;
    yard["value"] = yard.id;
  });
  store.commit("vehicle/setYardList", yards);
}

getYards();
</script>
