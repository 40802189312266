<template>
  <div class="container ip-cont">
  <q-form @submit="login()" class="cb-form">
    <div class="q-mb-md text-center">
      <q-img
        src="@/assets/images/carbnb-logo-transparent.webp"
        class="logo"
        style="width: 200px"
      />
    </div>
    <div class="q-px-xl q-pb-xl mobileLessPadding">
      <q-input
        v-model="email"
        outlined
        type="email"
        placeholder="Email"
        v-if="isUsingEmail"
        class="cb-input"
        lazy-rules
        :rules="[(val) => (val && val.length > 0) || 'Please enter your email address.']"
      />
      <q-input
        v-model="phone"
        outlined
        type="number"
        prefix="+63"
        class="cb-input"
        :rules="[
          (val) => (val && val.length > 0) || 'Mobile number is required',
        ]"
        v-else
      />

      <q-input
        v-model="password"
        outlined
        :type="!showPassword ? 'password' : 'text'"
        placeholder="Password"
        label-color="white"
        class="q-mb-md"
        :rules="[(val) => (val && val.length > 0) || 'Please enter your password']"
      >
        <template v-slot:append>
          <q-icon
            :name="showPassword ? 'visibility_off' : 'visibility'"
            class="cursor-pointer text-white"
            @click="showPassword = !showPassword"
            color="grey"
          />
        </template>
      </q-input>

      <div class="text-center q-my-md">
        <q-btn class="btn-primary" label="Sign In" color="primary" :type="'submit'" :loading="isLoggingIn" icon="login" />
        <q-btn class="btn-primary" label="Create Account" color="secondary" icon="app_registration" to="/signup"/>
      </div>
      <div class="q-pt-sm text-center text-subtitle2 text-weight-regular">
        <router-link to="/forgot-password"><span class="text-primary text-weight-regular">I forgot my password</span></router-link>
      </div>
      <div class="q-pt-sm text-center text-subtitle2 text-weight-regular">
        <router-link to="/verification"><span class="text-primary text-weight-regular">Verify My Account</span></router-link>
      </div>
    </div>
  </q-form>
</div>
</template>

<script>
import { ref } from "vue";
import { notify } from "../utils/notification";
export default {
  name: "Login",
  methods: {
    toggleLogin() {
      this.isUsingEmail = !this.isUsingEmail;
    },
    login() {
      this.isLoggingIn = true;
      const payload = {
        sign_in_type: "email",
        sign_in_identifier: this.type == "email" ? this.email : this.phone,
        sign_in_password: this.password,
      };
      this.$store.dispatch("login", payload).then((response) => {
        if (response.status == "success") {
          this.$store.commit("user/setUserData", response.data);
          this.$store.commit("user/validateMobileNumber");
          this.$router.push("/search");
        } else if (response.status == 'unverified_email') {
          this.$router.push("/verification");
        } else {
          notify("primary", response);
          this.isLoggingIn = false;
        }
      }).catch(() => {
        this.isLoggingIn = false;
      })
    }
  },
  setup() {
    return {
      email: ref(""),
      phone: ref(""),
      password: ref(""),
      isUsingEmail: ref(true),
      type: ref("email"),
      showPassword: ref(false),
      isLoggingIn: ref(false)
    };
  },
};
</script>
<style scoped>
.cb-form {
  max-width: 500px;
}
a{
  font-size: 14px;
}
a:hover{
  text-decoration: underline;
}
</style>
