<template>
  <div class="container profileDashboard justify-center">
    <ProfileSidebar />
    <section >
      <div class="flex justify-between full-width q-mt-sm">
        <h5 class="q-ma-none" v-if="!isLoading && booking">Your booking code is {{ booking.booking_code }}</h5>
      </div>

      <q-separator class="q-my-sm" />
      <div class="flex items-start justify-between mobileFlexReverse">
        
        <div class="booking-details mobileFullWidth">
          <LoadingBooking v-if="isLoading" />
          <h6 v-if="!isLoading && booking" class="text-accent">Booking Details</h6>
          <div class="booking-details-list flex gap-10" v-if="!isLoading && booking">
            <span
              ><b>Your booking status is</b>
              <BookingStatusBadge :status="booking.status" />
            </span>
            <span
              ><b>Pickup Date:</b> {{ booking.stringified_pickup_date }}</span
            >
            <span
              ><b>Return Date:</b>
              {{ booking.stringified_estimated_return_date }}</span
            >
            <span
              ><b>Pickup Address:</b>
              <p class="booking-location" v-if="yardBranch.length > 0">
                {{ yardBranch[0].address }}
              </p>
              <p v-else class="booking-location">{{ booking.address }}
              </p>
            </span>
            <span v-if="booking.payment_date">
              <b>Payment Date:</b>{{ filterDate(booking.payment_date) }}
            </span>

            <div
              class="text-subtitle1 text-black title text-bold q-mt-lg q-mb-sm"
              v-if="booking.status == 3"
            >
              Operators Payment Accounts:
              <span class="text-caption text-weight-regular"
                >You can pay the remaining balance or operators fee to the
                accounts below:</span
              >
            </div>
            <div class="full-width" v-if="booking.status == 3">
              <span
                v-for="acc in booking.payment_account_info.slice(0, 3)"
                :key="acc.id"
              >
                <b>{{ paymentOptions[acc.account_provider - 1].label }}:</b>
                {{ acc.account_number }}
              </span>
            </div>

            <br /><br />
            <div class="full-width">
              <q-card class="q-pa-none no-shadow">
                <h6 class="text-accent">
                  Payment Breakdown
                </h6>
                <p>The overall pricing breakdown is sent to your email and will be displayed upon [<q-icon name="shopping_cart"/>Proceed Payment]. The reservation fee displayed is VAT inclusive.</p>
                <q-card-section
                  class="flex justify-between q-py-none q-pb-sm q-px-none"
                >
                  <div class="text-subtitle2">
                    <span> Remaining Balance: </span>
                  </div>

                  <div class="text-body2 text-right">
                    <span>PHP {{ booking.operators_fee }} </span>
                  </div>
                </q-card-section>

                <q-card-section
                  class="flex justify-between q-py-none q-pb-sm q-px-none"
                >
                  <div class="text-subtitle2">Reservation Fee</div>

                  <div class="text-body2 text-right" v-if="booking.status != 3">
                    <span>PHP {{ booking.m_reservation_fee }}</span>
                  </div>
                  <div class="text-body2 text-right" v-else>
                    <span
                      ><p class="strike q-mb-none">PHP {{ booking.m_reservation_fee }}</p></span
                    >
                  </div>
                </q-card-section>
                <q-separator />

                <q-card-section
                  class="flex justify-between q-py-sm q-px-none"
                  v-if="booking.status != 3"
                >
                  <div class="q-pl-sm text-subtitle2">Total Price:</div>

                  <div class="text-subtitle2 text-right text-bold q-mb-md">
                    PHP {{ booking.m_total_rental_fee }}
                  </div>

                  <q-btn
                    label="Proceed Payment"
                    v-if="booking.status == 2"
                    @click="openPaymentApp()"
                    icon="shopping_cart"
                    color="primary"
                    class="full-width q-my-sm btn-primary"
                  />
                  <q-btn
                    label="Cancel Booking"
                    icon="event_busy"
                    color="secondary"
                    @click="showCancelDialog = !showCancelDialog"
                    v-if="booking.status == 1 || booking.status == 2 || booking.status == 3"
                    class="full-width q-my-sm btn-primary"
                  />
                </q-card-section>

                <q-card-section
                  class="flex justify-between q-py-sm q-px-none"
                  v-else
                >
                  <div class="text-subtitle2">Remaining Balance:</div>

                  <div class="text-subtitle2 text-right text-bold q-mb-md">
                    PHP {{ booking.operators_fee }}
                  </div>

                  <q-btn
                    text="Pay Now"
                    v-if="booking.status == 2"
                    @click="openPaymentApp()"
                    color="primary"
                  />
                  <div v-if="booking.status == 3 && booking.extension != null">
                    <q-btn
                      label="Pay For Extension"
                      @click="openPaymentApp()"
                      color="primary"
                    />
                    <p>Your booking has pending extension to settle. </p>
                  </div>
                </q-card-section>
                <q-card-section>
                  <div
                    v-if="booking.status == 1 || booking.status == 2"
                  >
                    <div class="text-subtitle2 text-bold">
                      For Reservation
                    </div>
                    <div class="text-caption">
                      You need to pay for the reservation fee to reserve the vehicle. The remaining balance must be settled on or before vehicle pick up via the operator's account (GCash or Bank Transfer) to be sent to your email.<br><br>
                      This booking reservation will expire after 1 hour if not paid and will appear available to other clients for booking.
                    </div>
                  </div>
                  <div v-if="booking.status == 3">
                    <div class="text-subtitle2 text-bold">
                      Remaining Balance
                    </div>
                    <div class="text-caption">
                      Do not forget to pay the remaining balance on or before the vehicle pick up date.<br><br>
                      For concerns regarding this booking, please contact Carbnb.
                    </div>
                  </div>
                  <div class="bottom-note" v-if="booking.status == 4">
                    <div class="text-subtitle1 text-white text-bold">
                      Note
                    </div>
                    <div class="text-caption text-weight-light">
                      If you need to extend your booking, please contact Carbnb.
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>
        <div class="booking-vehicle car-wrapper q-pa-sm mobileFullWidth">
          <VehicleCard :booking="booking" v-if="!isLoading && booking" />
          <LoadingVehicleCard v-if="isLoading" />
        </div>
      </div>
      <q-dialog v-model="showCancelDialog">
        <q-card style="min-width: 350px" class="column justify-center items-center content-center">
          <q-form @submit="cancelBooking">
          <q-card-section>
            <div class="text-h6">Cancel Booking?</div>
            <p>Please indicate the reason why you are cancelling this booking.</p>
          </q-card-section>
          <q-card-section class="q-pt-none">
            <div class="q-py-xs">
              <q-input 
                v-model="cancelReason"
                autofocus
                placeholder="Reason.."
                :rules="[(val) => (val && val.length > 0) || 'Please enter the reason of your cancellation.']"
              />
            </div>
          </q-card-section>

          <q-card-actions align="right" class="text-primary">
            <q-btn
              color="primary"
              label="No"
              icon="close"
              :disabled="isCancellingBooking"
              v-close-popup />
            <q-btn
              color="primary"
              label="Yes"
              :loading="isCancellingBooking"
              type="submit"
              icon="done"
              />
          </q-card-actions>
          </q-form>
        </q-card>
    </q-dialog>
    </section>
  </div>
</template>

<script setup>
import ProfileSidebar from "../components/Profile/ProfileSidebar.vue";
import VehicleCard from "../components/Profile/VehicleCard";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref } from "vue";
import moment from "moment";
import { notify } from "../utils/notification";
import BookingStatusBadge from "../components/Globals/BookingStatusBadge.vue";
import Providers from "../assets/json/paymentProvider.json";
import LoadingBooking from "../components/LoadingSkeletons/LoadingBooking.vue";
import LoadingVehicleCard from "../components/LoadingSkeletons/LoadingVehicleCard.vue"
import { useQuasar } from 'quasar';

const route = useRoute();
const router = useRouter();
const store = useStore();
const booking_id = route.params.id;
const booking = ref();
const yardBranch = ref([])
const paymentOptions = Providers.providers;
const isLoading = ref(true);
const $q = useQuasar();
const surname = store.state.user.data.last_name;
const showCancelDialog = ref(false);
const cancelReason = ref('');
const isCancellingBooking = ref(false);

function openPaymentApp() {
  $q.loading.show({ message: 'For a while..' });

  // Retrieve the payment details from the server.
  store.dispatch("payment/getPaymentDetails", {
    booking_code: booking.value.booking_code,
    surname: surname
  }).then((response) => {
    if (response.status == 'payment_request_retrieved') {
      router.push({
        name: 'Payment',
        params: {
          id: booking_id
        }
      })
    } else if (response.status == 'payment_already_received') {
      $q.dialog({
        title: 'Payment Received',
        message: 'The payment for this booking has already been received.'
      }).onOk(() => {
        router.push({
          name: 'Booking',
          params: {
            id: booking_id
          }
        })
      })
    }
  }).catch((e) => {
    if (e.code == 404) {
        $q.dialog({
            title: 'Booking Not Found',
            message: 'The booking was not found. Please contact Carbnb if you think that this is a mistake.'
        }).onOk(() => {
            router.push("/bookings");
        }).onCancel(() => {
            router.push("/bookings");
        }).onDismiss(() => {
            router.push("/bookings");
        })
    }
  }).finally(() => {
    $q.loading.hide();
  })
  // window.open(process.env.VUE_APP_PAYMENT_URL + '?booking_code=' + booking.value.booking_code + '&surname=' + store.state.user.userData.last_name, "_blank");
}
// TODO: reduce the call to 1
function loadBooking() {
  store.dispatch("booking/fetchSingleBooking", booking_id).then((res) => {
    if (res.status == "success") {
      booking.value = res.booking
      store
        .dispatch("vehicle/fetchSingleVehicle", res.booking.vehicle_slug)
        .then((r) => {
          if (r.status == "success") {
            store.commit("booking/setBookingVehicle", r.data);
            yardBranch.value = store.state.vehicle.yardList.filter((item) => item.id == res.booking.vehicle.yard_id)
            isLoading.value = false
          }
        });
    }
  });
}
function filterDate(date) {
  date = date.replaceAll("-","/")
  return moment(date, "YYYY-MM-DD hh:mm:ss").format("MMM DD YYYY hh:mm a");
}
function cancelBooking() {
  isCancellingBooking.value = true;
  store.dispatch("booking/cancelBooking", {
    booking_id: booking_id,
    reason: cancelReason.value
  }).then((response) => {
    if (response.data.status == "success") {
      showCancelDialog.value = false;
      notify("positive", "You have cancelled your booking.");
      setTimeout(() => {
        router.go();
      }, 2000);
    }
  }).catch(() => {
    notify("negative", "There has been an error while trying to cancel your booking.");
  }).finally(() => {
    isCancellingBooking.value = false;
    showCancelDialog.value = false;
  });
}

loadBooking();
</script>
<style scoped>
.booking-details-list span {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.strike {
  text-decoration: line-through;
}
.booking-details {
  width: 65%;
  padding: 10px 30px 10px 0px;
}
.booking-vehicle {
  width: 35%;
}

.bottom-note {
  background-color: #dadada;
  padding: 10px 15px;
  color: white;
}
.bottom-note .text-caption {
  font-weight: unset;
  font-size: unset;
}
.booking-location {
  max-width: 200px;
  text-align: right;
}
</style>
