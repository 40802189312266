<template>
  <section>
    <div class="car-header-title q-mx-md mobileNoMargin ">
      <div class="q-pb-md text-h5 text-bold desktop-hide">
        {{ vehicle.vehicle_name }}
      </div>
      <div class="car-header-subicons desktop-hide">
        <div class="flex items-center q-my-sm">
          <q-icon name="fa-solid fa-user q-pr-sm" />
          {{ vehicle.number_of_seats }} seats
        </div>
        <div class="text-capitalize flex items-center q-my-sm">
          <q-icon name="fa-solid fa-car q-pr-sm " />{{ vehicle.type }}
        </div>
        <div class="flex items-center q-my-sm">
          <q-icon name="fas fa-cogs m-2 q-pr-sm" />{{ vehicle.transmission }}
        </div>
      </div>
    </div>
    <q-card bordered>
      <q-card-section class="flex">
        <!------------------------------------ Pick Up Date Input -------------------------------------->
        <div class="full-width q-my-sm">
          <div class="q-my-sm">Pickup Date</div>
          <q-input outlined 
            v-model="inputPickupDate" 
            dense @click="$refs.qDateProxys.show()"
            :disable="isLoading"
          >
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  cover
                  transition-show="scale"
                  transition-hide="scale"
                  ref="qDateProxys"
                >
                  <q-date
                    @update:model-value="(val) => updatePickupDate(val)"
                    v-model="inputPickupDate"
                    :options="optionDates"
                    years-in-month-view
                    :default-year-month="
                      moment(new Date()).format('YYYY/MM')
                    "
                    :navigation-min-year-month="
                      moment(new Date()).format('YYYY/MM')
                    "
                    mask="YYYY-MM-DD"
                    @input="$refs.qDateProxys.hide()"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Close"
                        color="primary"
                        flat
                      />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>          
        </div>
        <!------------------------------------ Pick Up Time Input -------------------------------------->
        <div class="full-width q-my-sm">
          <div class="q-my-sm">Pickup Time</div>
          <q-input
            outlined
            v-model="inputPickupTime"
            dense
            @click="$refs.qTimeProxys.show()"
            :disable="isLoading"
          >
            <template v-slot:prepend>
              <q-icon name="access_time" class="cursor-pointer">
                <q-popup-proxy
                  cover
                  transition-show="scale"
                  transition-hide="scale"
                  ref="qTimeProxys"
                >
                  <q-time
                    @update:model-value="(val) => updatePickupTime(val)"
                    v-model="inputPickupTime"
                    mask="hh:mm a"
                    format12h
                    @input="$refs.qTimeProxys.hide()"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Close"
                        color="primary"
                        flat
                      />
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <!------------------------------------ Duration -------------------------------------->
        <div class="full-width q-my-sm">
          <div class="q-my-sm">Duration</div>
          <div class="flex q-pb-sm">
            <div class="full-width">
              <q-select
                dense
                outlined
                clearable
                class="full-width mobileFullWidth"
                v-model="inputDuration"
                :options="durationOptions"
                @update:model-value="(val) => updateDuration(val)"
                :disable="isLoading"
              />
            </div>
          </div>          
        </div>
      </q-card-section>
      <!------------------------------------ Pick Up Date Label -------------------------------------->
      <q-card-section class="flex justify-between q-py-sm q-px-md">
        <q-banner class="text-white q-mb-lg bg-red" v-if="isAvailable == false">
          <template v-slot:avatar>
            <q-icon name="info" color="white" />
          </template>
          <span>{{ availabilityMessage }}</span>
        </q-banner>
        <div class="text-accent">Pick Up Date</div>
        <div class="text-subtitle2">
          {{ labelPickupDate }}
        </div>
      </q-card-section>
      <!------------------------------------ Return Date Label -------------------------------------->
      <q-card-section class="flex justify-between q-py-sm q-px-md">
        <div class="text-accent">Return Date</div>
        <div class="text-subtitle2">
          {{ labelReturnDate }}
        </div>
      </q-card-section>
      <q-card-section class="flex justify-between q-py-sm q-px-md">
        <div class="text-accent">Price</div>
        <div class="text-subtitle2 text-positive">
          <q-skeleton type="text" style="width: 100px" v-if="isLoading" />
          <span v-else>{{ price.finalPrice }}</span>
        </div>
      </q-card-section>
      <q-card-section>
        <div v-if="isLoggedIn">
          <q-banner v-if="user.mobile_number_verified_at == null && user.allow_bookings_on_unverified_numbers == false" inline-actions class="text-white bg-warning">
            Please verify your mobile number to continue booking this vehicle.
            <template v-slot:action>
              <q-btn flat color="white" label="Verify" to="/dashboard" />
            </template>
          </q-banner>
        </div>
        <q-btn
          class="btn-primary"
          label="Book Now"
          :loading="isLoading"
          icon="check_circle_outline"
          @click="book"
          :disable="!isAvailable"
        />
        <q-btn
          class="btn-primary"
          color="secondary"
          label="Select Another Vehicle"
          to="/search"
          icon="arrow_back"
        />
      </q-card-section>
    </q-card>
    <q-dialog
      v-model="showFinalModal" 
      :persistent="true"
      :maximized="$q.screen.lt.md"
    >
    <ModalTripDetails style="min-width: 400px" />
    </q-dialog>
    <q-card bordered class="q-mt-md">
      <q-card-section>
        <div class="text-accent">Pickup Address</div>
        <div class="text-subtitle2">{{ vehicle.pickup_address }}</div>
      </q-card-section>
      <q-card-section>
        <iframe
          :src="
            'https://maps.google.com/maps?q=' +
            vehicle.location_latitude +
            ',' +
            vehicle.location_longhitude +
            '&hl=es;z=14&amp;output=embed'
          "
          width="100%"
          height="400px"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </q-card-section>
    </q-card>
  </section>
</template>

<script setup>
/** --------------------- Imports ------------------------ */
import moment from "moment";
import data from "@/assets/json/vehicle.json";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { defineProps, computed, ref } from "vue";
import { filterDate } from "../../utils/filterData";
import { getReturnDateTime } from "@/utils/searchQuery";
import { computePrice } from "../../utils/pricing";
import { useQuasar } from "quasar";
import ModalTripDetails from "../ModalTripDetails.vue";

/** --------------------- Declarations ------------------- */
const store = useStore();
const router = useRouter();
const user = store.state.user.data;
const $q = useQuasar();
const vehicle = computed(() => store.state.vehicle.vehicleData);
const query   = computed(() => store.state.vehicle.vehicleSearchQuery);
const durationOptions = ref(data.quick_selection_duration_options);
const isLoading = ref(true);
const isLoggedIn = computed(() => store.state.isLoggedIn);
const showFinalModal = ref(false);
const price = computed(() => computePrice(
    vehicle.value.type,
    props.booking_details.pickupdate,
    props.booking_details.pickupTime,
    props.booking_details.returndate,
    props.booking_details.returnTime,
    false,
    vehicle.value.yard_id
  )
);

// Construct the pick up date and return date from the vuex store
// This will be assigned to the current date inputs in this side bar.
const pickupDateTime = query.value.pickupdate + " " + query.value.pickupTime;
const returnDateTime = query.value.returndate + " " + query.value.returnTime;
const labelPickupDate = ref(filterDate(pickupDateTime));
const labelReturnDate = ref(filterDate(returnDateTime));
const props = defineProps(["booking_details"]);
const isAvailable = ref(true);
const availabilityMessage = ref('This vehicle is no longer available on your selected date ranges. Please set another date or select another vehicle.');

/** --------------------- Models ------------------- */
const inputPickupDate = ref(moment(query.value.pickupdate, "YYYY-MM-DD").format("YYYY-MM-DD"));
const inputPickupTime = ref(moment(query.value.pickupTime, "hh:mm:a").format("hh:mm a"));
const inputDuration = ref(query.value.duration);

// Initiate a check availability.
checkAvailability();
/** --------------------- Functions ------------------- */
/**
 * Gets triggered when the pick up date input was changed.
 * @param date
 */
function updatePickupDate(date) {
  if (date) {
    store.commit("vehicle/setSearchPickupDate", date);
    if (inputDuration.value) {
      getReturnDateTime(inputDuration.value);
    }
    labelPickupDate.value = filterDate(query.value.pickupdate + " " + query.value.pickupTime)
    labelReturnDate.value = filterDate(query.value.returndate + " " + query.value.returnTime)
    checkAvailability();
  }
}

/**
 * Gets triggered when the pick up time has been changed.
 * @param time
 */
function updatePickupTime(time) {
  if (time) {
    time = moment(time, "hh:mm a").format("hh:mm a");
    store.commit("vehicle/setSearchPickupTime", time);
    if (inputDuration.value) {
      getReturnDateTime(inputDuration.value);
    }
    labelPickupDate.value = filterDate(query.value.pickupdate + " " + query.value.pickupTime)
    labelReturnDate.value = filterDate(query.value.returndate + " " + query.value.returnTime)
    checkAvailability();
  }
}

/**
 * Gets triggered when the duration is changed.
 * @param val
 */
function updateDuration(val) {
  if (val) {
    getReturnDateTime(val);
    labelPickupDate.value = filterDate(query.value.pickupdate + " " + query.value.pickupTime)
    labelReturnDate.value = filterDate(query.value.returndate + " " + query.value.returnTime)
    checkAvailability();
  }
}
/**
 * Calls the API to check the vehicles availability by passing the pickup date and return date.
 */
 function checkAvailability() {
  isLoading.value = true;
  store.dispatch('vehicle/checkAvailability', vehicle.value.id).then((res) => {
    console.log(res.status)
    if (res.status == 'available') {
      isAvailable.value = true;
    } else if (res.status == 'not_available') {
      isAvailable.value = false;
      availabilityMessage.value = 'This vehicle is no longer available on your selected date ranges. Please set another date or select another vehicle.';
    } else {
      isAvailable.value = false;
      availabilityMessage.value = 'This vehicle is temporarily unavailable at the moment. Please select another vehicle.';
    }
  }).catch((err) => {
    console.error(err);
  }).finally(() => {
    isLoading.value = false;
  });
}

/**
 * Initiates when the book now button has been clicked.
 */
function book() {
  // Require the user to login or create an account first.
  if (!isLoggedIn.value) {
    $q.dialog({
        title: 'Sign In',
        message: 'To continue booking this vehicle, you must Sign In or Create an Account.',
        cancel: false,
        persistent: true
      }).onOk(() => {
        router.push('/login');
      }).onOk(() => {
        router.push('/login');
      }).onCancel(() => {
        router.push('/login');
      }).onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
      })
  }

  if (user.mobile_number_verified_at  == null && user.allow_bookings_on_unverified_numbers == false) {
    $q.dialog({
        title: 'Mobile Number Verification',
        message: 'Please verify your mobile number to continue booking this vehicle. You can find it on the bottom of your dashboard.',
        cancel: false,
        persistent: true
      }).onOk(() => {
        router.push('/dashboard');
      }).onOk(() => {
        router.push('/dashboard');
      }).onCancel(() => {
        router.push('/dashboard');
      }).onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
    })
  }

  showFinalModal.value = true;
}
</script>

<style>

</style>